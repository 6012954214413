import axios from 'axios'

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || ''
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
// console.log(process.env.VUE_APP_API_URL)
// http://192.168.0.122:9090/
let config = {
  baseURL: 'https://95easyload.com/'
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
}

const _http = axios.create(config)

//  request interceptors 
_http.interceptors.request.use(
  config => {
    // Do something before request is sent
    return config
  },
  error => {
    // Do something with request error
    return Promise.reject(error)
  }
)

// response interceptor
_http.interceptors.response.use(
  res => {
    // Do something with response data
    // console.log(res.data);
    // console.log(res)
    // if (res.data.code == 401) {
    //   Vue.prototype.$message.error(res.data.message)
    //   router.push('/login')
    // }
    // else if (res.data.code == 403) {
    //   Vue.prototype.$message.error(res.data.message)
    //   router.push('/forbidden')
    // }
    return res
  },
  error => {
    // if not token return login
    console.log('err：', error)
    return Promise.reject(error)
  }
)

export default _http;
