import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
const cn = {
    message: {
        'occasion': '随时随地找桌',
        'date': '请选择日期',
        'confirmText': '确定',
        'ps': '请选择',
        'abus': '关于我们',
        'abusDesc': '胡桃里音乐酒馆始建于 2014 年，隶属中国最大的综合音乐实体集团一一合纵文化。胡桃里将音乐、文艺、川菜馆、红酒吧融于一体，以酒吧 ｜ 餐厅 ｜ 咖啡馆混血儿的全新融合模式，打造一站式娱乐新体验，是比酒吧更具文化氛围的夜生活新地标。',
        'ENVIRONMENT': '环境',
        'DISHES': '菜品',
        'SPECIALDISHES': '特色菜品',

    },
    ...zhLocale
}

export default cn